@media (min-width: 768px) {
    .layout-content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 2rem;
    }
}

.menubar-wrapper {
    background-color: #f8f9fa;
    border-bottom: 1px solid #d9d9d9;
}