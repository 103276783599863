/* Subscriptions.css */
.subscriptions-container {
    /* display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center; */
    /* padding: 20px; */
    margin: 0 auto;
}

h2 {
    color: #4a5057;
}

@media (min-width: 767px) {
    .back-button-container {
        margin-left: 65px;
    }
    .subscription-card {
        width: 300px;
    }
}


@media (max-width: 767px) {
    .back-button-container {
        margin-left: 10px;
    }
    .subscription-card {
        width: 100%;
    }
}

.subscriptions-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    /* padding: 20px; */
} 

.subscription-card {
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.subscription-info-card {
    margin: 0 auto;
    max-width: 600px;
}

.subscription-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.subscription-details {
    margin-bottom: 15px;
    font-size: 0.9rem;
}

.user-card {
    margin: 0 auto;
    max-width: 500px;
}

.home-container {
    margin: 0 auto;
    max-width: 600px;
}