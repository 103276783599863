/* Children.css */
.children-container {
    padding: 20px;
  }
  
.p-datatable {
  margin: 0 auto;
  width: 100%;
  /* max-width: 800px; */
}

.p-datatable-header {
  font-weight: bold;
}

.p-datatable-tbody > tr > td {
  text-align: center;
}

.p-datatable-tbody > tr > td {
  text-align: left;
}