.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
}
  
.login-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}