@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-component {
  font-family: 'DM Sans', sans-serif;
}

small {
  color: #4b5057;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  padding: 2rem;
}

.menubar h2 {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1.5;
}

.custom-button {
  background: transparent;
  color: #495057;
  border: none;
}

.custom-button:hover {
  background: #e9ecef;
}

.custom-button:focus {
  box-shadow: none;
}

/* override default button */
.p-button-primary {
  background: #266842;
  border: 1px solid #266842;
}

.p-button-primary {
  background: #266842;
  border: 1px solid #266842;
}

.p-button-primary:focus {
  box-shadow: 0 0 0 0.2rem #2668424a;
}

.p-button-primary:hover {
  background: #38875a;
  border: 1px solid #38875a;
}

.p-inputtext:enabled:hover {
  border-color: #266842;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem #2668424a!important;
}